<template>
  <ProgressReportSection v-bind="$props">
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <UploadModal
      v-if="hasUploadPermissions"
      ref="uploadModal"
      :libraryEnabled="true"
      @completed="uploadCompleted"
      uploadLibraryType="private"
      :homeID="homeID"
    />
    <div
      v-if="!hasUploadPermissions && (!documents || documents.length === 0)"
      class="text-theme-100 mt-12 mb-6 flex flex-col sm:flex-row justify-start items-center"
    >
      <IconExclamationMark class="w-5 h-5 mr-4 flex-shrink-0" />
      <span>{{ $t('ProgressReport.NoDocumentsFoundAdminManaged') }}</span>
    </div>
    <div
      v-if="hasUploadPermissions"
      class="text-theme-250 font-bold text-darkblue mt-12 mb-6 flex flex-col sm:flex-row justify-end items-center"
    >
      <div
        class="flex flex-row cursor-pointer text-lightblue-link text-100 font-semibold mt-4 sm:mt-0 focus:outline-none focus:underline transition ease-in-out duration-150"
        @click="showUploadDialog"
      >
        <IconAdd class="mr-1" />
        <span>{{ $t('Radius.QualityAssessment.Action.AddDocument') }}</span>
      </div>
    </div>

    <div
      v-if="hasUploadPermissions && (!documents || documents.length === 0)"
      @click="showUploadDialog"
      class="w-full border-2 border-dashed border-gray-300 bg-gray-100 flex flex-col sm:flex-row text-center items-center px-6 py-7 rounded-md cursor-pointer text-100"
    >
      <IconExclamationMark class="w-5 h-5 mr-4 flex-shrink-0" />
      {{ $t('Radius.QualityAssessment.NoDocumentsFound') }}
      <button class="text-lightblue-link ml-1 font-semibold focus:outline-none lowercase">
        {{ $t('Radius.QualityAssessment.Action.AddDocument') }}
      </button>
    </div>
    <div v-if="documents && documents.length > 0">
      <div v-if="!hasUploadPermissions" class="flex justify-end text-50 text-lightblue-text mt-4">
        <span>{{ $t('ProgressReport.DocumentsAddedByAdmin') }}</span>
      </div>
      <div
        class="w-full px-6 py-4 flex items-center justify-between bg-white text-darkblue rounded-md border border-gray-200 mt-4 mb-1"
        v-for="(doc, index) in visibleDocumentsLengthCapEnabled
          ? documents.slice(0, visibleDocumentsLengthCap)
          : documents"
        :key="index"
        :class="{
          [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
        }"
      >
        <button
          class="flex text-left items-center focus:outline-none cursor-pointer flex-1"
          @click="$helper.showDocumentDetailsModal(doc, hasUploadPermissions)"
        >
          <IconFile class="flex-shrink-0" :document="doc" />
          <div class="min-w-2xl ml-8">
            <span class="block text-theme-150 font-semibold">{{ $filters.documentname(doc) }}</span>
            <span class="block text-theme-100">
              <span>Geupload op {{ doc.created_at | date }}</span>
              <span
                v-if="doc.expiration_date"
                :class="{
                  'text-red-500 font-semibold': $filters.monthsremaining(doc.expiration_date) <= 0,
                  'text-yellow-400 font-semibold':
                    $filters.monthsremaining(doc.expiration_date) > 0 &&
                    $filters.monthsremaining(doc.expiration_date) <= 2
                }"
              >
                - Verloopt op {{ doc.expiration_date | date }}</span
              >
            </span>
          </div>
        </button>
        <div class="flex text-lightblue-hover">
          <button
            v-if="hasUploadPermissions"
            class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
            @click="replaceDocument(doc)"
          >
            <IconReplace class="flex-shrink-0 group-hover:text-darkblue" />
            <span
              class="absolute py-1 px-2 text-50 bg-darkblue text-white rounded-md transform translate-y-7 opacity-0 invisible group-hover:visible group-hover:opacity-100 transition ease-in-out duration-150"
            >
              {{ $t('Radius.QualityAssessment.Action.ReplaceDocument') }}
            </span>
          </button>
          <button
            class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
            @click="$helper.downloadDocument(doc)"
          >
            <IconDownload class="flex-shrink-0 group-hover:text-darkblue" />
            <span
              class="absolute py-1 px-2 text-50 bg-darkblue text-white rounded-md transform translate-y-7 opacity-0 invisible group-hover:visible group-hover:opacity-100 transition ease-in-out duration-150"
            >
              {{ $t('Radius.QualityAssessment.Action.DownloadDocument') }}
            </span>
          </button>
          <button
            v-if="hasUploadPermissions"
            class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
            @click="
              $modal.confirm(() => {
                deleteDocument(doc);
              }, 'ConfirmDeleteDocument')
            "
          >
            <IconTrash class="flex-shrink-0 group-hover:text-darkblue" />
            <span
              class="absolute py-1 px-2 text-50 bg-darkblue text-white rounded-md transform translate-y-7 opacity-0 invisible group-hover:visible group-hover:opacity-100 transition ease-in-out duration-150"
            >
              {{ $t('Radius.QualityAssessment.Action.RemoveDocument') }}
            </span>
          </button>
        </div>
      </div>
      <div class="text-50 font-semibold leading-5 ml-6 mt-3">
        <span
          class="text-lightblue-link focus:outline-none focus:underline transition ease-in-out duration-150"
          v-if="visibleDocumentsLengthCapEnabled && documents && documents.length > visibleDocumentsLengthCap"
          @click="visibleDocumentsLengthCapEnabled = false"
        >
          {{ $t('Radius.QualityAssessment.ViewMoreFiles') | format(documents.length - visibleDocumentsLengthCap) }}
        </span>
      </div>
    </div>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </ProgressReportSection>
</template>

<script>
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import UploadModal from '@/components/modals/UploadModal';
import ProgressReportSection from '@/views/ProgressReport/Sections/ProgressReportSection';

export default {
  name: 'ProgressReportSectionFileUpload',
  extends: ProgressReportSection,
  inheritAttrs: false,
  components: {
    ProgressReportSection,
    UploadModal
  },
  props: {
    uploadAdminOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    documents() {
      if (!this.paragraphUserData || !this.paragraphUserData.documents) {
        return [];
      }

      return this.paragraphUserData.documents;
    },
    hasUploadPermissions() {
      if (this.isAdminOnly) {
        return this.$permissions.hasAdminRole();
      }

      return this.$permissions.has('progress-report-home-find') && this.$permissions.has('progress-report-home-update');
    }
  },
  data() {
    return {
      replacementDocument: null,
      visibleDocumentsLengthCapEnabled: true,
      visibleDocumentsLengthCap: 3,
      isAdminOnly: false
    };
  },
  mounted() {
    if (this.uploadAdminOnly || this.paragraph.block_type.toLowerCase() === 'file_upload_admin_only') {
      this.isAdminOnly = true;
    }
  },
  methods: {
    showUploadDialog() {
      this.replacementDocument = null;
      this.$refs.uploadModal.show();
    },

    async uploadCompleted(addedDocuments) {
      try {
        if (this.replacementDocument) {
          await this.deleteDocument(this.replacementDocument);
        }
      } catch {
        // Do nothing
      }

      try {
        let documentIDs = addedDocuments.map(doc => {
          return Number(doc.id);
        });

        if (this.paragraphUserData) {
          documentIDs = documentIDs.concat(
            this.paragraphUserData.documents.map(doc => {
              return Number(doc.id);
            })
          );
        }

        await this.updateDocumentIDs(documentIDs);
      } catch {
        // Document error
      }
    },

    replaceDocument(targetDocument) {
      this.showUploadDialog();
      this.replacementDocument = targetDocument;
    },

    async deleteDocument(targetDocument) {
      if (!this.paragraphUserData) {
        return;
      }

      const remainingDocumentIDs = this.paragraphUserData.documents
        .filter(doc => {
          return doc.id !== targetDocument.id;
        })
        .map(doc => {
          return doc.id;
        });

      await this.updateDocumentIDs(remainingDocumentIDs);
    },

    async updateDocumentIDs(documentIDs) {
      await this.$apollo.mutate({
        mutation: GraphQLMutation.ProgressReportCreateUserData,
        variables: {
          homeID: this.homeID,
          paragraphID: this.paragraph.id,
          documentIDs
        },
        update: (
          queryCacheStore,
          {
            data: {
              createProgressReportHome: { progressReportHome }
            }
          }
        ) => {
          const queryInfo = {
            query: GraphQLQuery.ProgressReportUserData,
            variables: {
              homeID: this.homeID
            }
          };

          const data = queryCacheStore.readQuery(queryInfo);

          let found = false;
          data.userDataParagraphs.map(userDataParagraph => {
            if (userDataParagraph.id === progressReportHome.id) {
              found = true;
              return progressReportHome;
            }
            return userDataParagraph;
          });

          if (!found) {
            data.userDataParagraphs.push(progressReportHome);
          }

          queryCacheStore.writeQuery({
            ...queryInfo,
            data
          });

          this.$emit('refreshUserData');
        }
      });
    }
  }
};
</script>
