<template>
  <div
    class="flex flex-col justify-center items-center text-100 w-full pt-5 pb-4 bg-white rounded-md border border-lightblue text-lightblue-text focus:outline-none"
  >
    <div class="flex flex-row justify-between items-center w-full text-darkblue font-semibold mb-2 px-4">
      <span class="flex-grow">{{ $t('ActivityList.Activity') }}</span>
      <span class="mx-4 hidden sm:inline">{{ $t('ActivityList.Owner') }}</span>
      <span class="ml-4 min-w-28 hidden sm:inline">{{ $t('ActivityList.ExpiryDate') }}</span>
    </div>
    <span class="w-full text-center text-100 text-lightblue-text" v-if="!activities || activities.length === 0">{{
      $t('ActivityList.NoActivitiesFound')
    }}</span>
    <div
      v-for="(item, index) in activities"
      :key="index"
      class="flex flex-col w-full"
      :class="{
        'bg-lightblue-50': index % 2,
        [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${Math.min(
          index,
          6
        )}`]: true
      }"
    >
      <div
        class="flex flex-col sm:flex-row justify-between items-start sm:items-center group px-4 py-2 cursor-pointer w-full"
        :class="{
          'text-red-500 font-semibold': isExpired(item),
          'text-yellow-400 font-semibold': willExpire(item)
        }"
      >
        <div class="flex flex-row flex-grow w-full sm:w-auto" @click="toggleItem(item)">
          <div
            class="flex justify-center items-center rounded-full focus:outline-none w-5 h-5 mr-2 flex-shrink-0"
            :class="{
              'border border-lightblue bg-white': !item.finished,
              'border-progress-fill bg-progress-fill border-2 text-white animation-general-overscale-in animation-once animation-fill-both animation-300':
                item.finished
            }"
          >
            <IconCheck v-if="item.finished" />
          </div>
          <ScrollerSpan>{{ item.title }}</ScrollerSpan>
        </div>
        <div class="mx-0 sm:mx-4 mt-2 sm:mt-0 text-50 sm:text-100" @click="toggleItem(item)">
          <span class="inline sm:hidden mr-2 text-darkblue font-semibold">{{ $t('ActivityList.Owner') }}:</span>
          <span>{{ item.owner | empty('-') }}</span>
        </div>
        <div
          class="flex-shrink-0 ml-0 sm:ml-4 flex items-center min-w-28 text-50 sm:text-100"
          @click="removeItem(item)"
        >
          <span class="inline sm:hidden mr-2 text-darkblue font-semibold">{{ $t('ActivityList.ExpiryDate') }}:</span>
          <span v-if="item.document">{{ item.document.expiration_date | date('DD-MM-YYYY') }}</span>
          <span v-else-if="item.expiration_date">{{ item.expiration_date | date('DD-MM-YYYY') }}</span>
          <span v-else>-</span>
          <button
            v-if="$permissions.has('activity-update')"
            class="focus:outline-none ml-2 block sm:hidden group-hover:block"
          >
            <IconTrash class="w-4 h-4 text-gray-400" />
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-row items-center mx-4 mb-2"
        v-if="item.document"
        @click="$helper.showDocumentDetailsModal(item.document, true, documentMutated)"
      >
        <button
          class="flex text-left items-center ml-0 sm:ml-6 mr-8 px-4 py-1 focus:outline-none cursor-pointer rounded-full bg-white border border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover"
        >
          <IconFile class="flex-shrink-0" :document="item.document" />
          <div class="flex flex-col flex-1 ml-3">
            <span class="text-50 text-darkblue font-semibold flex-1 break-all">{{ item.document.name }}</span>
          </div>
        </button>
      </div>
    </div>
    <div
      v-if="$permissions.has('activity-create')"
      class="flex flex-row justify-start items-center w-full cursor-pointer text-lightblue-link text-100 font-semibold p-4 focus:outline-none focus:underline transition ease-in-out duration-150 -mb-4"
      @click="createItem"
    >
      <IconAdd class="mr-2" />
      <span>{{ $t('ActivityList.Action.AddActivity') }}</span>
    </div>
  </div>
</template>

<script>
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import ScrollerSpan from '@/components/ScrollerSpan';

export default {
  name: 'ActivityList',
  components: {
    ScrollerSpan
  },
  apollo: {
    activities: {
      query: GraphQLQuery.ActivitiesActive,
      variables() {
        const monthAgoDate = new Date();
        monthAgoDate.setDate(monthAgoDate.getDate() - 5);

        return {
          hideDate: monthAgoDate.toISOString().split('T')[0]
        };
      },
      update(data) {
        return (data.activities || []).sort((a, b) => {
          if (a.finished && !b.finished) {
            return 1;
          }

          if (!a.finished && b.finished) {
            return -1;
          }

          return new Date(a.expiration_date)?.getTime() > new Date(b.expiration_date)?.getTime() ? 1 : -1;
        });
      },
      result() {
        this.$store.commit('routeUnread', {
          ActivityList: this.activities?.filter(activity => {
            return (
              !activity.finished &&
              activity.expiration_date &&
              this.$filters.monthsremaining(activity.expiration_date) <= 2
            );
          }).length
        });
      },
      skip() {
        return !this.$store.state.currentUser;
      }
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  methods: {
    isExpired(item) {
      if (!item || item?.finished) {
        return false;
      }

      if (item?.document && item.document.expiration_date) {
        return this.$filters.monthsremaining(item.document.expiration_date) <= 0;
      }

      if (item.expiration_date) {
        return this.$filters.monthsremaining(item.expiration_date) <= 0;
      }

      return false;
    },

    willExpire(item) {
      if (!item || item?.finished) {
        return false;
      }

      if (item?.document && item.document.expiration_date) {
        const monthsRemaining = this.$filters.monthsremaining(item.document.expiration_date);
        return monthsRemaining > 0 && monthsRemaining <= 2;
      }

      if (item.expiration_date) {
        const monthsRemaining = this.$filters.monthsremaining(item.expiration_date);
        return monthsRemaining > 0 && monthsRemaining <= 2;
      }

      return false;
    },

    async toggleItem(item) {
      if (!this.$permissions.has('activity-update')) {
        return;
      }

      item.finished = !item.finished;

      await this.$apollo.mutate({
        mutation: GraphQLMutation.ActivitiesUpdate,
        variables: {
          activityID: item.id,
          finished: item.finished
        }
      });
    },

    createItem() {
      if (!this.$permissions.has('activity-create')) {
        return;
      }

      this.$modal.show('ActivityListCreateItemModal', {
        completion: () => {
          this.$apollo.queries.activities.refetch();
        }
      });
    },

    removeItem(item) {
      if (!this.$permissions.has('activity-update')) {
        return;
      }

      this.$modal.confirm(async () => {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.ActivitiesDelete,
          variables: {
            activityID: item.id
          }
        });

        this.$apollo.queries.activities.refetch();
      }, 'ConfirmDeleteActivity');
    },

    documentMutated(mutationActivity, doc) {
      const items = this.activities.filter(activity => {
        if (!activity.document) {
          return false;
        }

        return doc.id === activity.document.id;
      });

      if (items && items.length > 0 && !items[0].finished) {
        this.toggleItem(items[0]);
      }
    }
  }
};
</script>
