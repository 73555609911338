<template>
  <BaseModal v-bind="$props" :title="$t('Modal.Access.Title')" @backdropClicked="hide">
    <span v-if="users && users.length" class="text-100 text-gray-500">{{ chapter.name }}</span>
    <ul v-if="users && users.length" class="-mx-6 max-w-lg access-user-list overflow-y-auto" style="max-height: 360px">
      <li v-for="(user, index) of users" :key="index" class="py-3 flex gap-3 justify-between px-6 overflow-hidden">
        <div class="flex items-center gap-3 min-w-0">
          <div class="flex-shrink-0">
            <img
              class="h-10 flex-shrink-0 w-10 rounded-sm mr-2"
              :src="$store.state.currentUser.avatarURL"
              alt="Profile Picture"
            />
          </div>
          <div class="overflow-hidden min-w-0">
            <div class="text-100 font-medium truncate text-black">
              {{ user.email }}
            </div>
            <div class="text-50 text-gray-600 truncate">
              <span v-if="user.expiration_date"> Toegang tot {{ user.expiration_date }} </span>
              <span v-else>{{ user.description }}, onbeperkt toegang</span>
            </div>
          </div>
        </div>
        <div v-if="user.expiration_date" class="flex items-center">
          <div v-if="updatingAccessForEmail === user.email" class="flex flex-col items-center">
            <img class="w-5 h-5" src="@/assets/loading-dark.gif" alt="Loading" />
            <span class="text-50 font-semibold text-darkblue">
              {{ $t('General.Loading') }}
            </span>
          </div>
          <div v-else-if="hasAccess(user)" class="flex flex-col items-end">
            <div
              class="text-orange text-100 whitespace-nowrap cursor-pointer hover:underline font-medium"
              @click="revokeAccess(user)"
            >
              {{ $t('Modal.Generic.RevokeAccess') }}
            </div>
            <div
              v-if="!hasAllAccess"
              class="hidden text-lightblue-link text-100 whitespace-nowrap cursor-pointer hover:underline font-medium"
              @click="grantAccess(user, true)"
            >
              {{ $t('Modal.Generic.GrantAllAccess') }}
            </div>
          </div>
          <div
            v-else
            class="text-lightblue-link text-100 whitespace-nowrap cursor-pointer hover:underline font-medium"
            @click="grantAccess(user)"
          >
            {{ $t('Modal.Generic.GrantAccess') }}
          </div>
        </div>
      </li>
    </ul>
    <div v-else-if="$apollo.loading" class="text-100 text-gray-500 flex gap-1 flex-col items-center">
      <img class="w-6 h-6" src="@/assets/loading-dark.gif" alt="Loading" />
      <span class="text-150 font-semibold ">
        {{ $t('General.Loading') }}
      </span>
    </div>
    <div v-else class="text-100 text-gray-500 flex gap-x-1">
      <span>{{ $t('Modal.Access.NoUsers') }}</span>
      <span @click="navigateInviteExternalUsers" class="cursor-pointer text-lightblue-link">{{
        $t('Modal.Access.NoUsers.Action.Invite')
      }}</span>
    </div>
    <div class="flex justify-end gap-2 mt-6">
      <ButtonElement @click.native="hide" variant="secondary-small">{{ $t('Modal.Generic.Cancel') }}</ButtonElement>
      <ButtonElement v-if="users && users.length" @click.native="hide" variant="primary-small">{{
        $t('Modal.Generic.Save')
      }}</ButtonElement>
    </div>
  </BaseModal>
</template>

<script>
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'AccessModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement
  },
  props: {
    chapter: {
      type: Object,
      required: true
    }
  },
  apollo: {
    users: {
      query: GraphQLQuery.ExternalUsers,
      update(data) {
        return data.getExternalUsers;
      }
    },
    chapterAccess: {
      query: GraphQLQuery.ChapterAccess
    }
  },
  data() {
    return {
      hasAllAccess: false,
      updatingAccessForEmail: null
    };
  },
  methods: {
    hasAccess(user) {
      if (!this.chapterAccess?.length || !this.chapter?.id) {
        return false;
      }

      return this.chapterAccess.some(ca => ca.chapter.id === this.chapter.id && ca.user.email === user.email);
    },

    async grantAccess(user, allChapters = false) {
      this.mutateAccess(user, true, allChapters);
    },

    revokeAccess(user) {
      this.mutateAccess(user, false);
    },

    async mutateAccess(user, grant = true, allChapters = false) {
      this.updatingAccessForEmail = user.email;
      await this.$apollo.mutate({
        mutation: GraphQLMutation.ChapterAccessUpdate,
        variables: {
          chapter: this.chapter.id,
          user: user.email,
          grantAccess: grant || false,
          grantAllChapters: allChapters || false
        }
      });

      this.$apollo.queries.chapterAccess.refetch();

      if (allChapters) {
        this.hasAllAccess = true;
      }

      this.updatingAccessForEmail = null;
    },

    navigateInviteExternalUsers() {
      this.hide();

      this.$router.push({
        name: 'ManageUsers'
      });
    }
  }
};
</script>

<style>
.access-user-list li:nth-child(odd) {
  @apply bg-gray-100;
}
</style>
