<template>
  <div v-if="visible" class="datepicker-container">
    <div class="bg-black opacity-50 top-0 left-0 fixed w-full h-full" @click="dismiss"></div>
    <DatePicker v-model="date" @change="didChange" title-format="MMMM" :lang="lang" range-separator="-" inline />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'DateSelector',
  components: {
    DatePicker
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: Date,
      default: null
    }
  },
  watch: {
    visible: {
      handler(value) {
        if (value) {
          this.date = this.value;
        }
      },
      immediate: true
    },
    date: {
      handler() {
        this.$emit('input', this.date);
      }
    }
  },
  data() {
    return {
      date: null,
      lang: {
        monthFormat: 'MMMM'
      }
    };
  },
  methods: {
    dismiss() {
      this.$emit('change', null);
    },
    didChange() {
      this.$emit('change', this.date);
      this.$emit('input', this.date);
    }
  }
};
</script>
